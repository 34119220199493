<template>
  <div
    class="main-content"
    v-bind:class="{ 'get-100': checkFirstTime == true }"
    id="maincontentapi"
  >
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "mainContent",
  data: function() {
    return {
      checkFirstTime: false,
    };
  },
  beforeMount() {
    this.handlenavbar();
    this.$eventBus.$on("easynavbar", () => {
      this.handlenavbar();
    });
  },

  methods: {
    handlenavbar() {
      const storedFirstTime = localStorage.getItem("firstTime");
      const currentRoute = this.$route.path;
      const isGetStartedUrl = currentRoute.includes("get-started");
      this.checkFirstTime =
        storedFirstTime !== null &&
        storedFirstTime == "true" &&
        isGetStartedUrl == true
          ? true
          : false;
      // console.log("this check", this.checkFirstTime)
    },
  },
};
</script>
<style scoped>
.main-content.main-open {
  width: calc(100% - 72px);
}
.main-content {
  transition: all 0.3s;
}
.get-100 {
  width: 100%;
  margin-top: 30px;
}
</style>
